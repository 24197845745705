<template>
    <div>
        <el-row class="row-tree recom">
            <el-col :span="20">
                <i class="icon icon-tree-recom"></i>
                <span class="title">推荐</span>
            </el-col>
            <el-col :span="4">
                <span class="more" @click="openInfo">更多>></span>
            </el-col>
        </el-row>
        <el-row :gutter="12" class="recom-cont">
            <el-col :span="6">
                <div class="tabs-box">
                    <div class="tabs" v-for="(recom, index) of recoms" :key="index" :class="[iconList[index], {'active': recom.isActive}]"  @click="handleRecom(recom,index)">
                        <div class="text">
                            <span class="title">{{recom.flmc}}</span>
<!--                            <p class="desc">{{recom.flsm}}</p>-->
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <hot-list :listData="nationalParkData"></hot-list>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {getThemesByPlateId} from "../../api/homeService";
import {getDyInfoBytabId} from "../../api/dyInfoService";
import hotList from "../../components/hotList";

export default {
  name: "recommend",
  props: {
    plateInfo: {}
  },
  components: {
    hotList
  },
  data() {
    return {
      nationalParkData: [],
                curShow: 0,
                recoms: [],
                iconList:[
                    "nature-park",
                    "national-park",
                    "nature-reserve"
                ],
            }
        },

        mounted(){
            this.getThemeByPlateId();
        },
        methods: {
            openInfo(){
                this.$router.push({
                    name: 'info',
                })
                //    ../counter/main
            },
            /**
             * 根据当前点击的推荐项获取数据
             * @param idx
             */
            handleRecom(recom,index) {
                getDyInfoBytabId(recom.id).then(res=>{
                    const data =res.data;
                    for(let i=0;i<data.length;i++){
                        data[i].span=8;
                        data[i].isFollow=false;
                    }
                    this.nationalParkData=data;
                });
                for(let i=0;i<this.recoms.length;i++){
                    this.recoms[i].isActive = false;
                }
                this.recoms[index].isActive = true;
            },
            /**
             * 根据板块ID获取主题分类
             */
            getThemeByPlateId(){
                getThemesByPlateId(this.plateInfo.id).then(res=>{
                    const data = res.data;
                    this.recoms = data;
                    this.handleRecom(this.recoms[0],0);
                })
            },

        },
    }
</script>
<style lang="scss">
    .index {
        position: relative;

        .row-tree {
            line-height: 30px;
            margin-bottom: 15px;
            margin-top: 18px;

            .icon {
                float: left;
                width: 25px;
                height: 32px;
                background: center no-repeat;
            }

            .title {
                font-size: 24px;
                float: left;
                margin-left: 10px;
            }

            .more {
                float: right;
                font-size: 12px;
                cursor: pointer;
            }

            .icon-tree-recom {
                background: url(../../assets/images/icon_tree_recom.png)
            }
        }

        .row-tree.recom {
            border-bottom: 2px solid #039BE5;

            .title {
                color: #039BE5;
            }
        }

        .recom-cont{
            background:url(../../assets/images/recom-bg.png) no-repeat center;
            .tabs-box{
                background-color:#fff;
                .tabs{
                    font-size:24px;
                    color:#039BE5;
                    cursor:pointer;
                    padding:12px 9px 14px;
                    margin-bottom:1px;
                    border:1px solid #DADADA;
                    .text{
                        background-position:left center;
                        background-repeat:no-repeat;
                        padding-left:91px;
                        height:78px;
                        .title {
                          font-size: 24px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;
                          padding-top: 29px;
                        }
                        .desc{
                            max-height:38px;
                            font-size:12px;
                            line-height:18px;
                            margin-top:5px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .national-park .text{
                    background-image:url(../../assets/images/national_park.png);
                }
                .nature-reserve .text{
                    background-image:url(../../assets/images/nature_reserve.png);
                }
                .nature-park .text{
                    background-image:url(../../assets/images/nature_park.png);
                }

                .national-park.active .text{
                    background-image:url(../../assets/images/national_park_hov.png);
                }
                .nature-reserve.active .text{
                    background-image:url(../../assets/images/nature_reserve_hov.png);
                }
                .nature-park.active .text{
                    background-image:url(../../assets/images/nature_park_hov.png);
                }
                .tabs.active{
                    color:#fff;
                    background-image:linear-gradient(270deg,rgba(42,198,255,1),rgba(0,165,255,1));
                }
            }
        }
    }
</style>
