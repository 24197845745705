<template>
    <div class="index center">
        <component :is="item.zjdz" :key="item.id" :plateInfo="item" v-for="item in plateList"></component>

       <all-places-resource-ranking></all-places-resource-ranking>
<!--        <ul class="slide">-->
<!--            <el-popover-->
<!--                    placement="left"-->
<!--                    trigger="hover">-->
<!--                <div class="applets" ref="applets">-->
<!--                    <el-image :src="applets" width="160"></el-image>-->
<!--                </div>-->
<!--                <li class="routine" slot="reference">小<br/>程<br/>序</li>-->
<!--            </el-popover>-->
<!--            &lt;!&ndash;                TODO 暂时不展示此功能&ndash;&gt;-->
<!--&lt;!&ndash;            <li @click="toSupport" class="opinion" v-if="$store.state.homeSystem.kqtsjy">意<br/>见</li>&ndash;&gt;-->
<!--        </ul>-->
<!--        <div :style="{opacity: QRAlpha}" @click="QRAlpha = 0" class="bubble">-->
<!--            二维码<br/>隐藏在这-->
<!--            <i class="el-icon-close"></i>-->
<!--        </div>-->
<!--        <div :style="{bottom: bottom + 'px'}" class="noti">-->
<!--            <i @click="notiSlideDown" class="el-icon-close"></i>-->
<!--            <div class="applets">-->
<!--                <el-image :src="applets" width="160"></el-image>-->
<!--            </div>-->
<!--            <p>扫码进入客户端小程序</p>-->
<!--        </div>-->
        <el-dialog
                :visible.sync="centerDialogVisible"
                center
                custom-class="sugg-dialog"
                destroy-on-close
                title="投诉与建议"
                top="10vh"
                width="800px">

            <tsjy @closeVisible="closeDialog" ref="tsjy"></tsjy>
            <!--            <span class="dialog-footer" slot="footer">-->
            <!--    <el-button @click="centerDialogVisible = false">取 消</el-button>-->
            <!--    <el-button @click="saveRule" type="primary">确 定</el-button>-->
            <!--  </span>-->
        </el-dialog>
    </div>
</template>
<script>
    import InfoList from "../home/infoList";
    import Recommend from "../home/recommend";
    import GraphNews from "../home/graphNews";
    import AllPlacesResourceRanking from "../home/allPlacesResourceRanking";
    import {getPlateListByClass} from "../../api/homeService";
    import Tsjy from "../../components/tsjy";
    import {tsjyLoginState} from "../../api/personService";

    export default {
        components: {
            Tsjy,
            GraphNews,
            Recommend,
            InfoList,
            AllPlacesResourceRanking
        },
        data() {
            return {
                plateList: [],
                applets: require('@/assets/images/自然资源统一确权登记.png'),
                bottom: -220,
                QRAlpha: 0,
                timer: null,
                centerDialogVisible: false
            }
        },
        mounted() {
            this.getPlateList();
            this.timer = setInterval(this.noticeUp, 100);
        },
        methods: {
            //验证数据是否完整
            // saveRule() {
            //     let ts = this.$refs.tsjy.getTs();
            //     if (ts == null) {
            //         return null
            //     }
            // },
            //关闭弹窗
            closeDialog(data) {
                this.centerDialogVisible = data
            },


            getPlateList() {
                const data = {bklx: '1', qybz: '1'};
                getPlateListByClass(data).then(res => {
                    this.plateList = res.data;
                });
            },
            toSupport() {
                tsjyLoginState().then(res => {
                    this.centerDialogVisible = true;
                })
            },

            noticeUp() {
                if (this.bottom < 0) {
                    return this.bottom += 20
                } else {
                    clearInterval(this.timer)
                }
            },

            noticeDown() {
                if (this.bottom > -220) {
                    return this.bottom -= 20
                } else {
                    clearInterval(this.timer)
                    this.QRAlpha = 1
                    setTimeout(() => {
                        this.QRAlpha = 0
                    }, 5000)
                }
            },


            notiSlideDown() {
                this.timer = setInterval(this.noticeDown, 100)
            },

        }
    }
</script>
<style lang="scss">
    .index {
        position: relative;

        .more2 {
            cursor: pointer;
            position: absolute;
            line-height: 30px;
            top: 5px;
            right: 0;
        }

        .row-tree {
            line-height: 30px;
            margin-bottom: 15px;
            margin-top: 18px;

            .icon {
                float: left;
                width: 25px;
                height: 32px;
                background: center no-repeat;
            }

            .title {
                font-size: 24px;
                float: left;
                margin-left: 10px;
            }

            .more {
                float: right;
                font-size: 12px;
                cursor: pointer;
            }

            .icon-tree-recom {
                background: url(../../assets/images/icon_tree_recom.png)
            }

            .icon-tree-news {
                background: url(../../assets/images/icon_tree_news.png)
            }

            .icon-tree-policy {
                background: url(../../assets/images/icon_tree_policy.png)
            }
        }

        .row-tree.recom {
            border-bottom: 2px solid #039BE5;

            .title {
                color: #039BE5;
            }
        }

        .row-tree.news {
            border-bottom: 2px solid #FF9600;

            .title {
                color: #FF9600;
            }
        }

        .row-tree.policy {
            margin-top: 30px;
            border-bottom: 2px solid #FF6751;

            .title {
                color: #FF6751;
            }
        }

        .slide {
            position: fixed;
            top: 200px;
            right: 0;
            width: 40px;
            background-color: #fff;
            box-shadow: 0px 3px 5px 0px rgba(153, 153, 153, 0.3);

            li {
                padding: 45px 0 12px;;
                line-height: 22px;
                text-align: center;
                font-size: 14px;
                color: #333;
                cursor: pointer;
                background: center 15px no-repeat;
                border-bottom: 1px solid #ddd;
            }

            .routine {
                position: relative;
                border-top: 1px solid #ddd;
                background-image: url(../../assets/images/icon_routine.png);
            }

            .opinion {
                background-image: url(../../assets/images/icon_edit.png);
            }
        }

        .bubble {
            position: fixed;
            z-index: 99999;
            top: 176px;
            right: 36px;
            width: 98px;
            height: 49px;
            padding: 5px 0 0 0px;
            background: url(../../assets/images/icon_bubble.png) center;
            font-size: 12px;
            line-height: 20px;
            text-align: center;

            .el-icon-close {
                position: fixed;
                top: 184px;
                right: 112px;
                color: #1CB04F;
                font-size: 15px;
                cursor: pointer;
            }
        }

        .noti {
            padding: 10px 20px;
            position: fixed;
            right: 0;
            bottom: -220px;
            z-index: 999;
            background-color: #fff;
            border: 1px solid #dadada;

            .el-icon-close {
                position: absolute;
                top: 6px;
                right: 6px;
                font-size: 20px;
                cursor: pointer;
                z-index: 99;
            }

            .applets {
                width: 160px;
                height: 160px;
            }

            p {
                text-align: center;
                line-height: 38px;
                font-size: 16px;
            }
        }
    }

    .el-popover .applets {
        width: 160px;
    }
</style>
