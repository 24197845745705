<template>
  <div>
    <el-row class="row-tree news">
      <el-col :span="20">
        <i class="icon icon-tree-news"></i>
        <span class="title">{{ plateInfo.bkmc }}</span>
      </el-col>
      <el-col :span="4">
        <span class="more" @click="moreJump">更多>></span>
      </el-col>
    </el-row>

    <!-- 使用该组件时，必须传递数据 -->
    <news-list :imgSrc="imgSrc" :listData="graphInfoList"></news-list>
  </div>
</template>

<script>
import {getGraphInfoByClass, getGraphInfoListBySy} from "../../api/homeService";
import newsList from "../../components/newsList";

export default {
  name: "graphNews",
  components: {newsList},
  props: {
    plateInfo: {}
  },
  data() {
    return {
      graphInfoList: [],
      imgSrc: require('../../assets/images/footer/default-pic.jpg'),
    }
  },
  mounted() {
    this.getGraphInfoList();
    this.setDefaultPic();
  },
  methods: {
    getGraphInfoList() {
      getGraphInfoListBySy(this.plateInfo.zjmc).then(res => {
        this.graphInfoList = res.data;
      })
    },

    moreJump() {
      if (this.plateInfo.zjmc === 'news') {
        this.$router.push({
          name: 'news'
        })
      } else if (this.plateInfo.zjmc === 'policy') {
        this.$router.push({
          name: 'policy'
        })
      } else if (this.plateInfo.zjmc === 'tggg') {
        this.$router.push({
          name: 'notice'
        })
      }
    },
    setDefaultPic() {
      if (this.plateInfo.zjmc === 'news') {
        this.imgSrc = require('../../assets/images/footer/xwbd.jpg')
      } else if (this.plateInfo.zjmc === 'policy') {
        this.imgSrc = require('../../assets/images/footer/zcfg.jpg')
      } else if (this.plateInfo.zjmc === 'tggg') {
        this.imgSrc = require('../../assets/images/footer/tggg.jpg')
      }
    }
  }

}
</script>

<style scoped>

</style>
