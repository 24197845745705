<template>
    <div class="hot-list">
        <el-row :gutter="12">
            <el-col :key="index" :span="list.span" v-for="(list, index) of lists">
                <div class="list">
                    <img :src="list.ydlj" @click="detail(list.id)" @mouseenter="enter(list,$event)"
                         @mouseleave="leave(list,$event)"
                         class="pic"/>
                    <el-row>
                        <el-col :span="24">
                            <span :title="list.djdymc" class="title">{{list.djdymc}}</span>
                        </el-col>
                        <!--                        <el-col :span="6">-->
                        <!--                            <span>-->
                        <!--                                <i :class="[list.isFollow? 'icon-follow' : 'icon-unfollow']" class="icon"></i>-->
                        <!--                                {{list.followText==null?'关注':list.followText}}-->
                        <!--                            </span>-->
                        <!--                        </el-col>-->
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <p class="fl">
                                <i class="icon icon-type"></i>
                                <span :title="list.zylx" class="textSpan">{{list.zylx}}</span>
                            </p>
                        </el-col>
<!--                        <el-col :span="14">-->
<!--                            <p class="fr">-->
<!--                                <i class="icon icon-area"></i>-->
<!--                                <span v-if="list.mj && (list.mj/100)>10000">{{(list.mj/(100*10000)).toFixed(3)}}万平方公里</span>-->
<!--                                <span v-if="list.mj &&(list.mj/100)<=10000">{{(list.mj/100).toFixed(3)}}平方公里</span>-->
<!--                            </p>-->
<!--                        </el-col>-->
                        <el-col :span="24">
                            <p class="fl">
                                <i class="icon icon-location"></i>
                                <span>{{list.dq}}</span>
                            </p>
                        </el-col>
<!--                        <el-col :span="12">-->
<!--                            <p class="fr">-->
<!--                                <i class="icon icon-date"></i>-->
<!--                                <span>{{list.djsj}}</span>-->
<!--                            </p>-->
<!--                        </el-col>-->
                        <el-col>
                            <div class="fl">
                                <i class="icon icon-dbxszt"></i>
                                <span v-if="list.lxztlx === '1'">
                                  <a class="text-name">所有者职责履行主体:</a>
                                  <p :title="list.syzzzdllxzt" class="text-con">{{list.syzzzdllxzt}}</p>
                                </span>
                                <span v-else-if="list.lxztlx === '2'">
                                  <a class="text-name">所有者职责代理履行主体:</a>
                                  <p :title="list.syzzzdllxzt" class="text-con">{{list.syzzzdllxzt}}</p>
                                </span>
                                <span v-else>
                                  <a class="text-name">未知:</a>
                                  <p :title="list.syzzzdllxzt" class="text-con">{{list.syzzzdllxzt}}</p>
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>

    export default {
        props: {
            listData: {
                type: Array
            },
            followState: {
                type: Array
            }
        },

        data() {
            return {
                lists: this.listData,
            }
        },
        watch: {
            'listData': {
                handler(val) {
                    if (val != undefined) {
                        this.lists = val
                        for (let i = 0; i < this.lists.length; i++) {
                            if (this.lists[i].dtlj != null) {
                                var newImg = new Image()
                                newImg.src = this.lists[i].dtlj
                                if (newImg.complete) {
                                    newImg.onload = () => { // 图片加载成功后把地址给原来的img
                                        console.log('加载图片')
                                    }
                                }
                            }
                        }
                    }
                },
                immediate: true,
            }
        },
        updated() {
            this.setFollowState();
        },
        methods: {
            enter(list, e) {
                if (list.dtlj != null) {
                    // e.target.src = "http://qbl3y5oye.bkt.clouddn.com/loading.gif"
                    // var newImg = new Image()
                    // newImg.src = list.dtlj
                    // newImg.onload = () => { // 图片加载成功后把地址给原来的img
                    //     e.target.src = newImg.src
                    // }
                    e.target.src = list.dtlj;
                }
            },
            leave(list, e) {
                e.target.src = list.ydlj;
            },

            handleFollow(index) {
                this.lists[index].isFollow = !this.lists[index].isFollow
                this.lists[index].followText = this.lists[index].followText == '已关注' ? '关注' : '已关注'
            },
            /**
             * 根据当前单元号跳转
             * @param currentId
             */
            detail(currentId) {
                this.$router.push({
                    name: 'infoDetail',
                    query: {
                      zrzyDyId: currentId
                    }
                })
            },
            setFollowState() {
                if (this.followState != null) {
                    for (let i = 0; i < this.followState.length; i++) {
                        for (let j = 0; j < this.listData.length; j++) {
                            if (this.followState[i] == this.listData[j].id) {
                                this.lists[j].isFollow = true;
                                this.lists[j].followText = '已关注';
                            }
                        }
                    }
                }
            }
        }
    }
</script>
<style lang="scss">
    .hot-list {
        .el-row{
            display: flex;
            flex-wrap: wrap;
        }
        .list {
            padding: 4px;
            margin-bottom: 12px;
            cursor: pointer;
            background-color: #fff;
            border: 1px solid rgba(217, 217, 217, 1);

            .pic {
                width: 100%;
                height: 160px;
                margin-bottom: 10px;
            }

            .el-row {
                font-size: 14px;
                color: #333;
                padding: 0 4px;

                .el-col {
                    max-height: 34px;
                    line-height: 34px;
                    overflow: hidden;
                    .fl{
                      display: flex;
                      width:100%;
                      span{
                        display:flex;
                        overflow: hidden;
                        .text-name{
                          width:160px;
                        }
                        .text-con{
                          flex: 1;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        }
                        a{
                          display: inline-block;
                        }
                      }
                    }

                    .title {
                        font-size: 18px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    .icon {
                        float: left;
                        width: 18px;
                        height: 18px;
                        margin: 8px 5px 0 0;
                        background: center no-repeat;
                    }

                    .textSpan {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    .icon-type {
                        background-image: url(../assets/images/icon_type.png);
                    }

                    .icon-area {
                        background-image: url(../assets/images/icon_area.png);
                    }

                    .icon-location {
                        background-image: url(../assets/images/icon_location.png);
                    }

                    .icon-date {
                        background-image: url(../assets/images/icon_date.png);
                    }

                    .icon-dbxszt {
                        background-image: url(../assets/images/icon_dbxszt.png);
                    }

                    .icon-follow {
                        background-image: url(../assets/images/icon_follow.png);
                    }

                    .icon-unfollow {
                        background-image: url(../assets/images/icon_unfollow.png);
                    }
                }
            }
        }
    }

</style>
