<template>
    <div class="host-info-box">
        <el-tabs @tab-click="clickTab" class="hot-tabs" v-model="tab">
            <el-tab-pane name="new">
                <span slot="label">
                    最新鲜
                    <i class="el-icon-caret-bottom"></i>
                </span>
                <!-- 使用该组件时，必须传递数据 -->
                <hot-list :listData="newList"></hot-list>
            </el-tab-pane>
            <el-tab-pane name="hot">
                <span slot="label">
                    最热门
                    <i class="el-icon-caret-bottom"></i>
                </span>
                <!-- 使用该组件时，必须传递数据 -->
                <hot-list :listData="hot"></hot-list>
            </el-tab-pane>
        </el-tabs>
        <span class="more2" @click="openInfo">更多>></span>
    </div>
</template>

<script>
    import {getDyInfo} from "../../api/dyInfoService";
    import hotList from "../../components/hotList";
    import {getStates} from "../../api/personService";
    import storage from "../../utils/storage";

    export default {
        name: "infoList",
        components: {
            hotList
        },
        data() {
            return {
                tab:'new',
                hot: [],
                newList: [],
            }
        },
        methods: {
            clickTab(tab, event) {
                this.getDyInfos(tab.name)
            },
            getDyInfos(type) {
                getDyInfo(type).then(res => {
                    if (res.code === "0") {
                        for (let i = 0; i < res.data.length; i++) {
                            res.data[i].followText = "关注"
                            res.data[i].showDT = false
                            res.data[i].span = 6;
                        }
                        if (type === 'hot') {
                            this.hot = res.data;
                        } else if (type === 'new') {
                            this.newList = res.data;
                        }
                    }
                })
            },
            openInfo(){
                this.$router.push({
                    name: 'info',
                })
            //    ../counter/main
            },
        },
        created() {
            this.getDyInfos(this.tab);
        }
    }
</script>

<style lang="scss">
    .host-info-box{
        position: relative;
    }
    .hot-tabs {
        border: none;
        box-shadow: none;

        .el-tabs__header {
            margin: 20px 0 6px;
            border-bottom: none;
            background-color: transparent;

            .el-tabs__item {
                border: none;
                font-size: 16px;
                padding: 0 10px !important;
                color: #808080;
                height: 30px;
                line-height: 30px;
            }

            .el-tabs__item.is-active {
                color: #fff;
                background-color: #00BF43
            }

            .el-tabs__item:not(.is-active):hover {
                color: #808080;
            }

            .el-tabs__active-bar {
                display: none;
            }
        }

        .el-tabs__content {
            padding: 0 !important;
        }

        .el-tabs__nav-wrap::after {
            display: none;
        }
    }
</style>
