<template>
  <div class="all-places-resource-ranking">
    <el-row class="row-tree news">
      <el-col :span="20">
        <i class="icon icon-tree-news"></i>
        <span class="title">各地自然资源信息排名</span>
      </el-col>
<!--      <el-col :span="4">-->
<!--        <span class="more" @click="moreJump">更多>></span>-->
<!--      </el-col>-->
    </el-row>
    <div class="header-box">
      <div class="guid-box">
        <div class="fw-box">
          <div class="sum">
            <i class="gstj-icon"></i>
            <span class="text">信息上传总量：</span>
            <span class="num">{{ zrzyZs }}</span>
          </div>
        </div>
        <div class="select-box">
          <div class="time-select">
            <div v-for='(item,i) in timeBtn' :class="{to_active:shows==i}" class="time-btn"
                 @click="selectTabChange(i)">
              {{ item }}
            </div>
          </div>
          <div class="timepick-block">
            <el-date-picker v-model="gsDate" end-placeholder="结束时间" range-separator="~" start-placeholder="起始时间"
                            type="daterange"
                            unlink-panels
                            value-format="yyyy-MM-dd" @blur="shows=3">
            </el-date-picker>
          </div>
          <div class="confirm-btn">
            <button @click="gstjClick()">确定</button>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-box">
      <el-row :gutter="20">
        <el-col :span="10">
          <div class="grid-content left-content">
            <div id="pieChart" style="width:100%;height:336px;margin-top: 15px;"></div>
          </div>
        </el-col>
        <el-col :span="14">
          <div class="grid-content">
            <div id="linebarChart" style="width:100%;height:385px;margin-top: 15px;"></div>
            <div class="moreBtn">
              <i class="icon-line"></i>
              <a @click="showmore">{{ showMore ? '前三' : '更多' }}<i :class="[showMore? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i></a>
              <i class="icon-line"></i>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getHomeStatisticsByXzq, getHomeStatisticsByZylx} from "../../api/homeService";
import {abilitySort, dataCalculate} from "../../utils/utils";
import echarts from "echarts";

export default {
  name: "allPlacesResourceRanking",
  components: {},
  props: {
    plateInfo: {}
  },
  data() {
    return {
      zrzyZs:'',
      timeBtn: ['本年', '本季', '本月', '自定义'],
      shows: 0,
      gsDate: [],
      zrzy: [],
      regionalData: [],
      showMore:false,
    }
  },
  mounted() {
    this.selectTabChange(this.shows)
  },
  methods: {
    /**
     * 登记单元更多展示
     */
    showmore() {
      this.showMore = !this.showMore;
      const dataQuery = dataCalculate(this.shows);
      this.getXzqData(dataQuery);
    },
    /**
     * 时间触发
     * @param i
     */
    selectTabChange(i) {
      this.shows = i;
      const dataQuery = dataCalculate(this.shows);
      console.log(dataQuery)
      this.gsDate=[dataQuery.kssj,dataQuery.jssj]
      this.getZylx(dataQuery);
      this.getXzqData(dataQuery);
    },
    /**
     * 自定义事件查询
     */
    gstjClick() {
      let dataQuery;
      if (this.gsDate.length === 0) {
        this.$message.warning('请选择时间')
      }else {
        this.zrzy= [];
        this.regionalData = [];
        dataQuery = {kssj: this.gsDate[0], jssj: this.gsDate[1]}
        this.getZylx(dataQuery)
        this.getXzqData(dataQuery)
      }
    },
    /**
     * 环状图 按资源类型统计
     * @param dataQuery
     */
    getZylx(dataQuery) {
      getHomeStatisticsByZylx(dataQuery).then(res => {
        if (res.code === '0') {
          // this.zrzy = res.data
          this.zrzy = abilitySort(res.data,'cunt',false)
          console.log(this.zrzy)
          this.setPieChart()
        }
      })
    },
    /**
     * 柱状图 按行政区统计
     * @param dataQuery
     */
    getXzqData(dataQuery) {
      getHomeStatisticsByXzq(dataQuery).then(res => {
        if (res.code === '0' && res.data && res.data.length>0) {
          this.regionalData = this.showMore ? res.data?.reverse() : res.data?.slice(0,3)?.reverse();
          this.setLineBarChart();
        }else {
          this.regionalData = [];
          this.setLineBarChart();
        }
      })
    },

    moreJump() {
      this.$router.push({
        name: 'dataUploadRanking'
      })
    },
    setDefaultPic() {

    },
    setPieChart() {
      this.zrzyZs = 0;
      // 饼图
      var pieChart = echarts.init(document.getElementById("pieChart"));
      let data = []
      let max = 0
      let min = 0
      let legend = []
      for (let i = 0; i < this.zrzy.length; i++) {
        if (min > this.zrzy[i].cunt) {
          min = this.zrzy[i].cunt
        }
        if (max < this.zrzy[i].cunt) {
          max = this.zrzy[i].cunt
        }
        legend.push(this.zrzy[i].MC)
        data.push({value: this.zrzy[i].cunt, name: this.zrzy[i].MC})
        this.zrzyZs = this.zrzyZs + this.zrzy[i].cunt
      }
      // 使用刚指定的配置项和数据显示图表。
      var option = {
        title: {
          text: '登记单元类型占比',
          left: 'left',
          top: 0,
          textStyle: {
            color: '#313233',
            fontWeight: 'bold',
            fontFamily: 'Microsoft YaHei',
            fontSize: 18,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var color = params.color;//图例颜色
            var htmlStr = '<div>';
            htmlStr += params.name + ':<br/>';//x轴的名称
            htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' + color + ';"></span>';
            htmlStr += params.value + "(" + params.percent + "%)";
            htmlStr += '</div>';
            return htmlStr;
          }
        },
        //设置饼状图每个颜色块的颜色
        color: ['#FF667F', '#3377FF', '#66CCFF', '#00B377','#FF5500','#A694FF','#5C6477','#1ABBB5','#DBBB07'],
        legend: {
          orient: "vertical",
          top: 90,
          left: "55%",
          avoidLabelOverlap: true,
          icon: "circle",
          data: legend,
          formatter: function (name) {
            var data = option.series[0].data;//获取series中的data
            var total = 0;
            var tarValue;
            for (var i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            var p = ((tarValue / total) * 100);
            return name + " " + " " + p.toFixed(2) + "%";
          },
        },
        series: [
          {
            name: '资源类型',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ["30%", "54%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data:data
          }
        ]
      };
      pieChart.setOption(option);
      window.addEventListener("resize", function () { //浏览器大小调整echarts随之改变
        pieChart.resize();
      });
    },
    setLineBarChart() {
      // 柱状图
      var linebarChart = echarts.init(document.getElementById("linebarChart"));
      let mc = []
      let data = []
      for (let i = 0; i < this.regionalData.length; i++) {
        mc.push(this.regionalData[i].MC)
        data.push(this.regionalData[i].cunt)
      }
      var option = {
        title: {
          text: '',
          left: 'left',
          top: 0,
          textStyle: {
            color: '#313233',
            fontWeight: 'bold',
            fontFamily: 'Microsoft YaHei',
            fontSize: 16,

          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          x: 90,
          y: 40,
          x2: 41,
          y2: 40,
        },
        xAxis: {
          type: 'value',
          min: 0,
          minInterval: 1,
          name: '数量'
        },
        yAxis: {
          type: 'category',
          data: mc,
          splitLine: {show: false},
          axisLine: {
            show: false
          },
          axisTick: {
            alignWithLabel: true,
            interval: 0
          },
          offset: 10,
          nameTextStyle: {
            fontSize: 15
          }
        },
        series: [
          {
            name: '数量',
            type: 'bar',
            data: data,
            barWidth: 14,
            barGap: 10,
            smooth: true,
            label: {
              normal: {
                show: true,
                position: 'right',
                offset: [5, -2],
                textStyle: {
                  color: '#F68300',
                  fontSize: 13
                }
              }
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                    0, 0, 1, 0,
                    [
                      {offset: 0, color: '#3977E6'},
                      {offset: 1, color: '#37BBF8'}

                    ]
                )
              }
            }
          }
        ]
      };
      linebarChart.setOption(option);
      window.addEventListener("resize", function () { //浏览器大小调整echarts随之改变
        linebarChart.resize();
      });
    },
  }

}
</script>

<style lang="scss">
.all-places-resource-ranking{
  .header-box{
    .guid-box {
      display: flex;
      align-items: center;
      flex-direction: row;
      border-bottom: 1px solid #E6E9F2;
      padding-bottom: 12px;
      .select-box {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        .confirm-btn button {
          width: 80px;
          height: 28px;
          background: #0099E6;
          border: none;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 28px;
          cursor: pointer;
        }
        .el-input__inner {
          height: 30px;
        }
        .el-date-editor .el-range__icon ,.el-date-editor .el-range-separator{
          height: 32px;
        }
        .el-date-editor--daterange.el-input__inner {
          width: 240px !important;
        }
        .timepick-block {
          width: 240px;
          margin-right: 12px;
        }
        .time-select {
          width: 320px;
          height: 28px;
          background: #FFFFFF;
          border: 1px solid #0099E6;
          display: flex;
          flex-direction: row;
          margin-left: auto;
          margin-right: 12px;
          .time-btn {
            flex: 1;
            height: 100%;
            background: #FFFFFF;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0099E6;
            line-height: 28px;
            text-align: center;
            cursor: pointer;
          }
          .to_active {
            background: #0099E6;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
      }
      .fw-box {
        display: flex;
        align-items: center;
        flex-direction: row;
        .sum {
          display: flex;
          align-items: center;
          margin-right: 35px;
          i {
            margin-right: 10px;
            display: inline-block;
            width: 28px;
            height: 28px;
            background: url(../../assets/images/tjfx/icon-fwl.png) no-repeat center center;
          }
          .text {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
          }
          .num {
            font-size: 30px;
            font-family: DIN;
            font-weight: bold;
            color: #000000;
            line-height: 30px;
          }
        }
      }
    }
  }
  .chart-box{
    .left-content{
      border-right: 1px solid #E6E9F2;
    }
    .grid-content{
      .moreBtn {
        height: 18px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0080FF;
        a {
          margin: 0px 8px 0px 8px;
        }
        .icon-line {
          width: 310px;
          height: 1px;
          background: #999999;
          border-radius: 1px;
        }
      }
    }

  }
}
</style>
