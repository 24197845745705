<template>
    <div class="news-list">
        <el-row :gutter="20">
            <el-col :span="9">
                <el-image v-if="listData[0]!=undefined && listData[0].ttwTpDO &&listData[0].ttwTpDO.ydlj!=undefined" :src="listData[0].ttwTpDO.ydlj" style="height: 240px"></el-image>
                <!--                <el-image src="http://rus5334py.hn-bkt.clouddn.com/zrzy/image/202006121320478611472.png" style="height: 240px" v-else></el-image>-->
                <img :src="imgSrc" style="height: 240px" v-else/>
            </el-col>
            <el-col :span="15">
                <el-table
                        :data="listData"
                        :show-header="isHeader"
                        @row-click="rowClick"
                        class="news-list-table"
                        height="240px"
                        style="width: 100%">
                    <el-table-column
                            label="标题"
                            prop="bt"
                            show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span class="title">{{ scope.row.bt }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="right"
                            label="日期"
                            prop="fbsj"
                            show-overflow-tooltip
                            width="180">
                        <template slot-scope="scope">
                            <span class="date">{{ scope.row.fbsj }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    props: {
        listData: {
            type: Array,
            default: () => []
        },
        imgSrc: {
            type: String,
            default: require('../assets/images/footer/default-pic.jpg')
        },
    },
    data() {
        return {
            isHeader: false,
        }
    },
    methods: {
        rowClick(row, column, event) {
            let name;
            if (row.lx === '1') {
                name = 'newsDetail'
            } else if (row.lx === '2') {
                name = 'noticeDetail'
            } else {
                name = 'policyDetail'
            }
            this.$router.push({
                name: name,
                query: {
                    id: row.id
                }
            });
        }
    }
}
</script>
<style lang="scss">
.news-list {
  //background:url(../assets/images/news-list-bg.png) center no-repeat;
  .news-list-table {
    td {
      font-size: 16px;
      color: #333;

      .title {
        cursor: pointer;
      }
    }

    td.is-right {
      color: #807E7E;
    }
  }

  .el-table::before {
    background-color: #ffffff;
  }
  .el-image{
      width: 100%;
  }

  .el-col-9 {
    img {
      width: 100%;
    }
  }
}

</style>
